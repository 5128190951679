<template>
  <div class="page">
    <!-- <div class="headpic-container">
      <div class="headpic-content">
        <span class="headpic-title">模块化插件</span>
        <span class="headpic-title">推广优化协助平台</span>
        <span class="headpic-tip"
          >快速选词，效果监控，风险预警等功能聚合，让团队和个人都能达成高效工作成就</span
        >
        <router-link to="/index">
          <span class="headpic-button">立即使用</span>
        </router-link>
      </div>
    </div>
    <div class="part2-container part-container">
      <div class="part-content">
        <span class="part-title">轻松上手</span>
        <span class="part-title">快速工作</span>
        <span class="part-tip mt20">简洁易懂的界面，海量创意词库</span>
        <span class="part-tip">人人都可上手，人人都是优秀投手</span>
      </div>
      <div class="part-img">
        <img src="../../assets/images/case1.png" />
      </div>
    </div>
    <div class="part1-container part-container">
      <div class="part-content">
        <span class="part-title">完备监控</span>
        <span class="part-title">提升效率</span>
        <span class="part-tip mt20">细致的数据分析，独立算法支持</span>
        <span class="part-tip">精确到分秒之间的计划指标监控</span>
      </div>
      <div class="part-img">
        <img src="../../assets/images/case1.png" />
      </div>
    </div> -->
    <div class="g-flex-center mt150">
      <span class="cl-000 font-600 ft50">抖脉，</span>
      <span class="cl-FF2626 font-600 ft50">1≠1</span>
    </div>
    <span class="ft18 cl-888 mt25"
      >1个插件，解决N个问题，让新人也可以轻松上手做投放</span
    >
    <div class="products">
      <div class="product-container">
        <div class="product-item">
          <img src="../../assets/images/xc.png" />
          <router-link to="/xc">
            <span class="product-title">选词</span>
          </router-link>
          <span class="product-tip">创意规划，不止于快</span>
          <router-link to="/xc">
            <span class="xc-button">点击查看</span>
          </router-link>
        </div>
        <div class="product-item">
          <img src="../../assets/images/yj.png" />
          <router-link to="/yj">
            <span class="product-title">预警</span>
          </router-link>
          <span class="product-tip">流量管理，降低风险</span>
          <router-link to="/yj">
            <span class="xc-button">点击查看</span>
          </router-link>
        </div>
      </div>
      <div class="company-container">
        <div class="company-content">
          <img src="../../assets/images/discover/jlqc.png" alt />
        </div>
      </div>
    </div>
    <!-- <div class="headpic-container">
      <div class="headpic-content">
        <span class="headpic-title">模块化插件</span>
        <span class="headpic-title">推广优化协助平台</span>
        <span class="headpic-tip">快速选词，效果监控，风险预警等功能聚合，让团队和个人都能达成高效工作成就</span>
        <router-link to="/index">
          <span class="headpic-button">立即使用</span>
        </router-link>
      </div>
    </div> -->
    <div class="part2-container part-container mt85">
      <div class="part-content">
        <span class="part-title">轻松上手</span>
        <span class="part-title">快速工作</span>
        <span class="part-tip mt20">简洁易懂的界面，海量创意词库</span>
        <span class="part-tip">人人都可上手，人人都是优秀投手</span>
      </div>
      <div class="part-img">
        <img src="../../assets/images/case1.png" />
      </div>
    </div>
    <div class="part1-container part-container">
      <div class="part-content">
        <span class="part-title">完备监控</span>
        <span class="part-title">提升效率</span>
        <span class="part-tip mt20">细致的数据分析，独立算法支持</span>
        <span class="part-tip">精确到分秒之间的计划指标监控</span>
      </div>
      <div class="part-img">
        <img src="../../assets/images/case1.png" />
      </div>
    </div>
    <!-- <div class="g-flex-center mt85">
      <span class="cl-000 font-600 ft40">与抖脉一同见证改变</span>
    </div> -->
    <div class="bottom-container">
      <span class="bottom-title">现在使用抖脉 开始创意投放</span>
      <router-link to="/index">
        <span class="bottom-button">立即使用</span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {},
  methods: {
    // ------------------ 点击方法
    // ------------------ 请求方法
    // ------------------ 其它
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .headpic-container {
    width: 100%;
    min-width: 1200px;
    height: 730px;
    background-image: url('../../assets/images/discover/headpic1.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    .headpic-content {
      background-image: url('../../assets/images/discover/headpic2.png');
      display: flex;
      width: 860px;
      height: 422px;
      flex-direction: column;
      align-items: center;
      margin-top: 190px;
      padding-top: 88px;
      .headpic-title {
        font-size: 46px;
        color: #000;
        line-height: 70px;
        font-weight: 600;
      }
      .headpic-tip {
        font-size: 16px;
        color: #88898d;
        margin-top: 39px;
      }
      .headpic-button {
        width: 254px;
        height: 59px;
        background: #ff2626;
        border-radius: 30px;
        margin-top: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        .pointer;
      }
    }
  }
  .part2-container {
    background: url('../../assets/images/discover/part2.png');
  }
  .part1-container {
    background: url('../../assets/images/discover/part1.png');
  }
  .part-container {
    width: 1540px;
    height: 746px;
    position: relative;
    .part-content {
      position: absolute;
      top: 200px;
      left: 169px;
      display: flex;
      flex-direction: column;
      .part-title {
        font-size: 50px;
        color: #000;
        font-weight: 600;
        line-height: 70px;
      }
      .part-tip {
        height: 48px;
        font-size: 18px;
        font-weight: 400;
        color: #86868b;
        line-height: 30px;
      }
    }
    .part-img {
      position: absolute;
      top: 156px;
      left: 504px;
      width: 888px;
      height: 494px;
      border-radius: 30px;
      border: 4px solid #000;
      img {
        height: 100%;
        width: 100%;
        border-radius: 30px;
      }
    }
  }
}
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1220px;
  margin-top: 50px;
  .product-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 404px;
    width: 1220px;
    .product-item {
      width: 560px;
      height: 404px;
      background: #f7f8fa;
      border: 3px solid #eaebed;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 110px;
        width: 110px;
        margin-top: 40px;
      }
      .product-title {
        font-size: 40px;
        font-weight: bold;
        color: #131925;
        line-height: 84px;
        margin-top: 30px;
      }
      .product-tip {
        font-size: 18px;
        font-weight: 400;
        color: #131925;
        line-height: 30px;
        margin-top: 24px;
      }
      .xc-button {
        width: 200px;
        height: 54px;
        background: #2878ff;
        border-radius: 27px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        .pointer;
      }
      .yj-button {
        width: 200px;
        height: 54px;
        background: #ff2626;
        border-radius: 27px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        .pointer;
      }
    }
  }
  .company-container {
    display: flex;
    flex-direction: row;
    height: 94px;
    width: 651px;
    border: dashed #333 1px;
    border-top: none;
    position: relative;
    .company-content {
      width: 283px;
      height: 65px;
      background: #f7f8fa;
      border: 2px solid #ededed;
      border-radius: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -32px;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0 auto;
      img {
        height: 53px;
        width: 194px;
      }
    }
  }
}
.bottom-container {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/images/discover/bottom.png');
  margin-top: 96px;
  .bottom-title {
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    margin-top: 126px;
  }
  .bottom-button {
    width: 254px;
    height: 59px;
    background: #ff2626;
    border-radius: 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
